@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?95793884');
  src: url('../font/fontello.eot?95793884#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?95793884') format('woff2'),
       url('../font/fontello.woff?95793884') format('woff'),
       url('../font/fontello.ttf?95793884') format('truetype'),
       url('../font/fontello.svg?95793884#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?95793884#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-note:before { content: '\e800'; } /* '' */
.icon-note-beamed:before { content: '\e801'; } /* '' */
.icon-music:before { content: '\e802'; } /* '' */
.icon-search:before { content: '\e803'; } /* '' */
.icon-flashlight:before { content: '\e804'; } /* '' */
.icon-mail:before { content: '\e805'; } /* '' */
.icon-heart:before { content: '\e806'; } /* '' */
.icon-heart-empty:before { content: '\e807'; } /* '' */
.icon-star:before { content: '\e808'; } /* '' */
.icon-star-empty:before { content: '\e809'; } /* '' */
.icon-user:before { content: '\e80a'; } /* '' */
.icon-users:before { content: '\e80b'; } /* '' */
.icon-user-add:before { content: '\e80c'; } /* '' */
.icon-video:before { content: '\e80d'; } /* '' */
.icon-picture:before { content: '\e80e'; } /* '' */
.icon-camera:before { content: '\e80f'; } /* '' */
.icon-layout:before { content: '\e810'; } /* '' */
.icon-menu:before { content: '\e811'; } /* '' */
.icon-check:before { content: '\e812'; } /* '' */
.icon-cancel:before { content: '\e813'; } /* '' */
.icon-cancel-circled:before { content: '\e814'; } /* '' */
.icon-cancel-squared:before { content: '\e815'; } /* '' */
.icon-plus:before { content: '\e816'; } /* '' */
.icon-plus-circled:before { content: '\e817'; } /* '' */
.icon-plus-squared:before { content: '\e818'; } /* '' */
.icon-minus:before { content: '\e819'; } /* '' */
.icon-minus-circled:before { content: '\e81a'; } /* '' */
.icon-minus-squared:before { content: '\e81b'; } /* '' */
.icon-help:before { content: '\e81c'; } /* '' */
.icon-help-circled:before { content: '\e81d'; } /* '' */
.icon-info:before { content: '\e81e'; } /* '' */
.icon-info-circled:before { content: '\e81f'; } /* '' */
.icon-back:before { content: '\e820'; } /* '' */
.icon-home:before { content: '\e821'; } /* '' */
.icon-link:before { content: '\e822'; } /* '' */
.icon-attach:before { content: '\e823'; } /* '' */
.icon-lock:before { content: '\e824'; } /* '' */
.icon-lock-open:before { content: '\e825'; } /* '' */
.icon-eye:before { content: '\e826'; } /* '' */
.icon-tag:before { content: '\e827'; } /* '' */
.icon-bookmark:before { content: '\e828'; } /* '' */
.icon-bookmarks:before { content: '\e829'; } /* '' */
.icon-flag:before { content: '\e82a'; } /* '' */
.icon-thumbs-up:before { content: '\e82b'; } /* '' */
.icon-thumbs-down:before { content: '\e82c'; } /* '' */
.icon-download:before { content: '\e82d'; } /* '' */
.icon-upload:before { content: '\e82e'; } /* '' */
.icon-upload-cloud:before { content: '\e82f'; } /* '' */
.icon-reply:before { content: '\e830'; } /* '' */
.icon-reply-all:before { content: '\e831'; } /* '' */
.icon-forward:before { content: '\e832'; } /* '' */
.icon-quote:before { content: '\e833'; } /* '' */
.icon-code:before { content: '\e834'; } /* '' */
.icon-export:before { content: '\e835'; } /* '' */
.icon-pencil:before { content: '\e836'; } /* '' */
.icon-feather:before { content: '\e837'; } /* '' */
.icon-print:before { content: '\e838'; } /* '' */
.icon-retweet:before { content: '\e839'; } /* '' */
.icon-keyboard:before { content: '\e83a'; } /* '' */
.icon-comment:before { content: '\e83b'; } /* '' */
.icon-chat:before { content: '\e83c'; } /* '' */
.icon-bell:before { content: '\e83d'; } /* '' */
.icon-attention:before { content: '\e83e'; } /* '' */
.icon-alert:before { content: '\e83f'; } /* '' */
.icon-vcard:before { content: '\e840'; } /* '' */
.icon-address:before { content: '\e841'; } /* '' */
.icon-location:before { content: '\e842'; } /* '' */
.icon-map:before { content: '\e843'; } /* '' */
.icon-direction:before { content: '\e844'; } /* '' */
.icon-compass:before { content: '\e845'; } /* '' */
.icon-cup:before { content: '\e846'; } /* '' */
.icon-trash:before { content: '\e847'; } /* '' */
.icon-doc:before { content: '\e848'; } /* '' */
.icon-docs:before { content: '\e849'; } /* '' */
.icon-doc-landscape:before { content: '\e84a'; } /* '' */
.icon-doc-text:before { content: '\e84b'; } /* '' */
.icon-doc-text-inv:before { content: '\e84c'; } /* '' */
.icon-newspaper:before { content: '\e84d'; } /* '' */
.icon-book-open:before { content: '\e84e'; } /* '' */
.icon-book:before { content: '\e84f'; } /* '' */
.icon-folder:before { content: '\e850'; } /* '' */
.icon-archive:before { content: '\e851'; } /* '' */
.icon-box:before { content: '\e852'; } /* '' */
.icon-rss:before { content: '\e853'; } /* '' */
.icon-phone:before { content: '\e854'; } /* '' */
.icon-cog:before { content: '\e855'; } /* '' */
.icon-tools:before { content: '\e856'; } /* '' */
.icon-share:before { content: '\e857'; } /* '' */
.icon-sun:before { content: '\e858'; } /* '' */
.icon-garden:before { content: '\e859'; } /* '' */
.icon-bag:before { content: '\e85a'; } /* '' */
.icon-calendar:before { content: '\e85b'; } /* '' */
.icon-login:before { content: '\e85c'; } /* '' */
.icon-logout:before { content: '\e85d'; } /* '' */
.icon-mic:before { content: '\e85e'; } /* '' */
.icon-mute:before { content: '\e85f'; } /* '' */
.icon-sound:before { content: '\e860'; } /* '' */
.icon-volume:before { content: '\e861'; } /* '' */
.icon-clock:before { content: '\e862'; } /* '' */
.icon-hourglass:before { content: '\e863'; } /* '' */
.icon-lamp:before { content: '\e864'; } /* '' */
.icon-industrial-building:before { content: '\e865'; } /* '' */
.icon-squiggle:before { content: '\e866'; } /* '' */
.icon-adjust:before { content: '\e867'; } /* '' */
.icon-block:before { content: '\e868'; } /* '' */
.icon-resize-full:before { content: '\e869'; } /* '' */
.icon-resize-small:before { content: '\e86a'; } /* '' */
.icon-popup:before { content: '\e86b'; } /* '' */
.icon-publish:before { content: '\e86c'; } /* '' */
.icon-window:before { content: '\e86d'; } /* '' */
.icon-arrow-combo:before { content: '\e86e'; } /* '' */
.icon-down-circled:before { content: '\e86f'; } /* '' */
.icon-left-circled:before { content: '\e870'; } /* '' */
.icon-right-circled:before { content: '\e871'; } /* '' */
.icon-up-circled:before { content: '\e872'; } /* '' */
.icon-down-open:before { content: '\e873'; } /* '' */
.icon-left-open:before { content: '\e874'; } /* '' */
.icon-right-open:before { content: '\e875'; } /* '' */
.icon-up-open:before { content: '\e876'; } /* '' */
.icon-down-open-mini:before { content: '\e877'; } /* '' */
.icon-left-open-mini:before { content: '\e878'; } /* '' */
.icon-right-open-mini:before { content: '\e879'; } /* '' */
.icon-up-open-mini:before { content: '\e87a'; } /* '' */
.icon-down-open-big:before { content: '\e87b'; } /* '' */
.icon-left-open-big:before { content: '\e87c'; } /* '' */
.icon-right-open-big:before { content: '\e87d'; } /* '' */
.icon-up-open-big:before { content: '\e87e'; } /* '' */
.icon-down:before { content: '\e87f'; } /* '' */
.icon-left:before { content: '\e880'; } /* '' */
.icon-right:before { content: '\e881'; } /* '' */
.icon-up:before { content: '\e882'; } /* '' */
.icon-down-dir:before { content: '\e883'; } /* '' */
.icon-left-dir:before { content: '\e884'; } /* '' */
.icon-right-dir:before { content: '\e885'; } /* '' */
.icon-up-dir:before { content: '\e886'; } /* '' */
.icon-down-bold:before { content: '\e887'; } /* '' */
.icon-left-bold:before { content: '\e888'; } /* '' */
.icon-right-bold:before { content: '\e889'; } /* '' */
.icon-up-bold:before { content: '\e88a'; } /* '' */
.icon-down-thin:before { content: '\e88b'; } /* '' */
.icon-left-thin:before { content: '\e88c'; } /* '' */
.icon-right-thin:before { content: '\e88d'; } /* '' */
.icon-up-thin:before { content: '\e88e'; } /* '' */
.icon-ccw:before { content: '\e88f'; } /* '' */
.icon-cw:before { content: '\e890'; } /* '' */
.icon-arrows-ccw:before { content: '\e891'; } /* '' */
.icon-level-down:before { content: '\e892'; } /* '' */
.icon-level-up:before { content: '\e893'; } /* '' */
.icon-shuffle:before { content: '\e894'; } /* '' */
.icon-loop:before { content: '\e895'; } /* '' */
.icon-switch:before { content: '\e896'; } /* '' */
.icon-play:before { content: '\e897'; } /* '' */
.icon-stop:before { content: '\e898'; } /* '' */
.icon-pause:before { content: '\e899'; } /* '' */
.icon-record:before { content: '\e89a'; } /* '' */
.icon-to-end:before { content: '\e89b'; } /* '' */
.icon-to-start:before { content: '\e89c'; } /* '' */
.icon-fast-forward:before { content: '\e89d'; } /* '' */
.icon-fast-backward:before { content: '\e89e'; } /* '' */
.icon-progress-0:before { content: '\e89f'; } /* '' */
.icon-progress:before { content: '\e8a0'; } /* '' */
.icon-progress-2:before { content: '\e8a1'; } /* '' */
.icon-progress-3:before { content: '\e8a2'; } /* '' */
.icon-target:before { content: '\e8a3'; } /* '' */
.icon-palette:before { content: '\e8a4'; } /* '' */
.icon-list:before { content: '\e8a5'; } /* '' */
.icon-list-add:before { content: '\e8a6'; } /* '' */
.icon-signal:before { content: '\e8a7'; } /* '' */
.icon-trophy:before { content: '\e8a8'; } /* '' */
.icon-mobile:before { content: '\e8ac'; } /* '' */
.icon-network:before { content: '\e8ad'; } /* '' */
.icon-cd:before { content: '\e8ae'; } /* '' */
.icon-inbox:before { content: '\e8af'; } /* '' */
.icon-install:before { content: '\e8b0'; } /* '' */
.icon-globe:before { content: '\e8b1'; } /* '' */
.icon-paper-plane:before { content: '\e8b7'; } /* '' */
.icon-lifebuoy:before { content: '\e8b9'; } /* '' */
.icon-briefcase:before { content: '\e8bb'; } /* '' */
.icon-suitcase:before { content: '\e8bc'; } /* '' */
.icon-dot:before { content: '\e8bd'; } /* '' */
.icon-dot-2:before { content: '\e8be'; } /* '' */
.icon-dot-3:before { content: '\e8bf'; } /* '' */
.icon-brush:before { content: '\e8c0'; } /* '' */
.icon-infinity:before { content: '\e8c2'; } /* '' */
.icon-erase:before { content: '\e8c3'; } /* '' */
.icon-chart-pie:before { content: '\e8c4'; } /* '' */
.icon-chart-line:before { content: '\e8c5'; } /* '' */
.icon-chart-bar:before { content: '\e8c6'; } /* '' */
.icon-chart-area:before { content: '\e8c7'; } /* '' */
.icon-tape:before { content: '\e8c8'; } /* '' */
.icon-graduation-cap:before { content: '\e8c9'; } /* '' */
.icon-ticket:before { content: '\e8cb'; } /* '' */
.icon-language:before { content: '\e8cc'; } /* '' */
.icon-air:before { content: '\e8ce'; } /* '' */
.icon-credit-card:before { content: '\e8cf'; } /* '' */
.icon-clipboard:before { content: '\e8d1'; } /* '' */
.icon-megaphone:before { content: '\e8d2'; } /* '' */
.icon-database:before { content: '\e8d3'; } /* '' */
.icon-key:before { content: '\e8d7'; } /* '' */
.icon-flow-cascade:before { content: '\e8d8'; } /* '' */
.icon-flow-branch:before { content: '\e8d9'; } /* '' */
.icon-flow-tree:before { content: '\e8da'; } /* '' */
.icon-flow-line:before { content: '\e8db'; } /* '' */
.icon-flow-parallel:before { content: '\e8dc'; } /* '' */
.icon-rocket:before { content: '\e8dd'; } /* '' */
.icon-gauge:before { content: '\e8de'; } /* '' */
.icon-traffic-cone:before { content: '\e8df'; } /* '' */
.icon-cc:before { content: '\e8e0'; } /* '' */
.icon-cc-by:before { content: '\e8e1'; } /* '' */
.icon-cc-nc:before { content: '\e8e2'; } /* '' */
.icon-cc-nc-eu:before { content: '\e8e3'; } /* '' */
.icon-cc-nc-jp:before { content: '\e8e4'; } /* '' */
.icon-cc-sa:before { content: '\e8e5'; } /* '' */
.icon-cc-nd:before { content: '\e8e6'; } /* '' */
.icon-cc-pd:before { content: '\e8e7'; } /* '' */
.icon-cc-zero:before { content: '\e8e8'; } /* '' */
.icon-cc-share:before { content: '\e8e9'; } /* '' */
.icon-cc-remix:before { content: '\e8ea'; } /* '' */
.icon-sweden:before { content: '\e919'; } /* '' */
.icon-db-shape:before { content: '\e91a'; } /* '' */
.icon-glass:before { content: '\e91c'; } /* '' */
.icon-eye-1:before { content: '\e91d'; } /* '' */
.icon-attention-alt:before { content: '\e91e'; } /* '' */
.icon-doc-1:before { content: '\e91f'; } /* '' */
.icon-mute-1:before { content: '\e920'; } /* '' */
.icon-mic-1:before { content: '\e921'; } /* '' */
.icon-down-big:before { content: '\e922'; } /* '' */
.icon-left-big:before { content: '\e923'; } /* '' */
.icon-key-1:before { content: '\e924'; } /* '' */
.icon-star-half:before { content: '\e925'; } /* '' */
.icon-star-half-alt:before { content: '\e926'; } /* '' */
.icon-move:before { content: '\e927'; } /* '' */
.icon-header:before { content: '\e928'; } /* '' */
.icon-move-1:before { content: '\e929'; } /* '' */
.icon-at:before { content: '\e92a'; } /* '' */
.icon-ok-circled:before { content: '\e934'; } /* '' */
.icon-ok-circled2:before { content: '\e935'; } /* '' */
.icon-ok-squared:before { content: '\e936'; } /* '' */
.icon-unlink:before { content: '\e949'; } /* '' */
.icon-link-ext:before { content: '\e94a'; } /* '' */
.icon-link-ext-alt:before { content: '\e94b'; } /* '' */
.icon-pin:before { content: '\e950'; } /* '' */
.icon-eye-off:before { content: '\e952'; } /* '' */
.icon-tag-1:before { content: '\e953'; } /* '' */
.icon-tags:before { content: '\e954'; } /* '' */
.icon-docs-1:before { content: '\e97f'; } /* '' */
.icon-folder-open:before { content: '\e984'; } /* '' */
.icon-cog-alt:before { content: '\e98e'; } /* '' */
.icon-wrench:before { content: '\e98f'; } /* '' */
.icon-basket-1:before { content: '\e990'; } /* '' */
.icon-volume-off:before { content: '\e997'; } /* '' */
.icon-volume-down:before { content: '\e998'; } /* '' */
.icon-volume-up:before { content: '\e999'; } /* '' */
.icon-headphones:before { content: '\e99a'; } /* '' */
.icon-clock-1:before { content: '\e99b'; } /* '' */
.icon-resize-vertical:before { content: '\e9a1'; } /* '' */
.icon-resize-horizontal:before { content: '\e9a2'; } /* '' */
.icon-zoom-in:before { content: '\e9a4'; } /* '' */
.icon-zoom-out:before { content: '\e9a5'; } /* '' */
.icon-right-big:before { content: '\e9c4'; } /* '' */
.icon-up-big:before { content: '\e9c5'; } /* '' */
.icon-right-hand:before { content: '\e9c6'; } /* '' */
.icon-left-hand:before { content: '\e9c7'; } /* '' */
.icon-up-hand:before { content: '\e9c8'; } /* '' */
.icon-down-hand:before { content: '\e9c9'; } /* '' */
.icon-expand:before { content: '\e9d5'; } /* '' */
.icon-collapse:before { content: '\e9d6'; } /* '' */
.icon-expand-right:before { content: '\e9d7'; } /* '' */
.icon-collapse-left:before { content: '\e9d8'; } /* '' */
.icon-eject:before { content: '\e9e4'; } /* '' */
.icon-target-1:before { content: '\e9e5'; } /* '' */
.icon-signal-1:before { content: '\e9e6'; } /* '' */
.icon-award:before { content: '\e9e7'; } /* '' */
.icon-desktop:before { content: '\e9e8'; } /* '' */
.icon-laptop:before { content: '\e9e9'; } /* '' */
.icon-tablet:before { content: '\e9ea'; } /* '' */
.icon-umbrella:before { content: '\e9f2'; } /* '' */
.icon-fighter-jet:before { content: '\e9f4'; } /* '' */
.icon-font:before { content: '\e9f6'; } /* '' */
.icon-bold:before { content: '\e9f7'; } /* '' */
.icon-italic:before { content: '\e9f8'; } /* '' */
.icon-text-height:before { content: '\e9f9'; } /* '' */
.icon-text-width:before { content: '\e9fa'; } /* '' */
.icon-align-left:before { content: '\e9fb'; } /* '' */
.icon-align-center:before { content: '\e9fc'; } /* '' */
.icon-align-right:before { content: '\e9fd'; } /* '' */
.icon-align-justify:before { content: '\e9fe'; } /* '' */
.icon-list-1:before { content: '\e9ff'; } /* '' */
.icon-indent-left:before { content: '\ea00'; } /* '' */
.icon-indent-right:before { content: '\ea01'; } /* '' */
.icon-list-bullet:before { content: '\ea02'; } /* '' */
.icon-list-numbered:before { content: '\ea03'; } /* '' */
.icon-strike:before { content: '\ea04'; } /* '' */
.icon-underline:before { content: '\ea05'; } /* '' */
.icon-superscript:before { content: '\ea06'; } /* '' */
.icon-subscript:before { content: '\ea07'; } /* '' */
.icon-table:before { content: '\ea08'; } /* '' */
.icon-columns:before { content: '\ea09'; } /* '' */
.icon-crop:before { content: '\ea0a'; } /* '' */
.icon-scissors:before { content: '\ea0b'; } /* '' */
.icon-paste:before { content: '\ea0c'; } /* '' */
.icon-ellipsis-vert:before { content: '\ea10'; } /* '' */
.icon-off:before { content: '\ea11'; } /* '' */
.icon-road:before { content: '\ea12'; } /* '' */
.icon-qrcode:before { content: '\ea14'; } /* '' */
.icon-barcode:before { content: '\ea15'; } /* '' */
.icon-book-1:before { content: '\ea16'; } /* '' */
.icon-ajust:before { content: '\ea17'; } /* '' */
.icon-check-empty:before { content: '\ea1a'; } /* '' */
.icon-circle:before { content: '\ea1b'; } /* '' */
.icon-circle-empty:before { content: '\ea1c'; } /* '' */
.icon-dot-circled:before { content: '\ea1d'; } /* '' */
.icon-asterisk:before { content: '\ea1e'; } /* '' */
.icon-gift:before { content: '\ea1f'; } /* '' */
.icon-fire:before { content: '\ea20'; } /* '' */
.icon-rocket-1:before { content: '\ea2a'; } /* '' */
.icon-bug:before { content: '\ea2b'; } /* '' */
.icon-certificate:before { content: '\ea2c'; } /* '' */
.icon-tasks:before { content: '\ea2d'; } /* '' */
.icon-filter:before { content: '\ea2e'; } /* '' */
.icon-beaker:before { content: '\ea2f'; } /* '' */
.icon-magic:before { content: '\ea30'; } /* '' */
.icon-truck:before { content: '\ea31'; } /* '' */
.icon-money:before { content: '\ea32'; } /* '' */
.icon-euro:before { content: '\ea33'; } /* '' */
.icon-pound:before { content: '\ea34'; } /* '' */
.icon-dollar:before { content: '\ea35'; } /* '' */
.icon-rupee:before { content: '\ea36'; } /* '' */
.icon-yen:before { content: '\ea37'; } /* '' */
.icon-rouble:before { content: '\ea38'; } /* '' */
.icon-try:before { content: '\ea39'; } /* '' */
.icon-won:before { content: '\ea3a'; } /* '' */
.icon-bitcoin:before { content: '\ea3b'; } /* '' */
.icon-sort:before { content: '\ea3c'; } /* '' */
.icon-sort-down:before { content: '\ea3d'; } /* '' */
.icon-sort-up:before { content: '\ea3e'; } /* '' */
.icon-sort-alt-up:before { content: '\ea3f'; } /* '' */
.icon-sort-alt-down:before { content: '\ea40'; } /* '' */
.icon-sort-name-up:before { content: '\ea41'; } /* '' */
.icon-sort-name-down:before { content: '\ea42'; } /* '' */
.icon-sort-number-up:before { content: '\ea43'; } /* '' */
.icon-sort-number-down:before { content: '\ea44'; } /* '' */
.icon-hammer:before { content: '\ea45'; } /* '' */
.icon-gauge-1:before { content: '\ea46'; } /* '' */
.icon-sitemap:before { content: '\ea47'; } /* '' */
.icon-spinner:before { content: '\ea48'; } /* '' */
.icon-coffee:before { content: '\ea49'; } /* '' */
.icon-food:before { content: '\ea4a'; } /* '' */
.icon-beer:before { content: '\ea4b'; } /* '' */
.icon-user-md:before { content: '\ea4c'; } /* '' */
.icon-stethoscope:before { content: '\ea4d'; } /* '' */
.icon-ambulance:before { content: '\ea4e'; } /* '' */
.icon-medkit:before { content: '\ea4f'; } /* '' */
.icon-h-sigh:before { content: '\ea50'; } /* '' */
.icon-hospital:before { content: '\ea51'; } /* '' */
.icon-building:before { content: '\ea52'; } /* '' */
.icon-smile:before { content: '\ea53'; } /* '' */
.icon-frown:before { content: '\ea54'; } /* '' */
.icon-meh:before { content: '\ea55'; } /* '' */
.icon-anchor:before { content: '\ea56'; } /* '' */
.icon-terminal:before { content: '\ea57'; } /* '' */
.icon-eraser:before { content: '\ea58'; } /* '' */
.icon-puzzle:before { content: '\ea59'; } /* '' */
.icon-shield:before { content: '\ea5a'; } /* '' */
.icon-extinguisher:before { content: '\ea5b'; } /* '' */
.icon-bullseye:before { content: '\ea5c'; } /* '' */
.icon-wheelchair:before { content: '\ea5d'; } /* '' */
.icon-android:before { content: '\ea5f'; } /* '' */
.icon-apple:before { content: '\ea60'; } /* '' */
.icon-linux:before { content: '\ea73'; } /* '' */
.icon-pagelines:before { content: '\ea76'; } /* '' */
.icon-stackexchange:before { content: '\ea7b'; } /* '' */
.icon-youtube-play:before { content: '\ea8a'; } /* '' */
.icon-blank:before { content: '\ea8b'; } /* '' */